var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.error
        ? _c(
            "v-col",
            [
              _vm.errorResponseTitle
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-alert",
                        { attrs: { dense: "", outlined: "", type: "error" } },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.errorResponseTitle) +
                              "\n      "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        _vm._l(_vm.widgetNotificationCenters, function (widget) {
          return _c(
            "v-col",
            {
              key: widget.widgetGallery.id,
              staticClass: "pb-0",
              attrs: { cols: "12" },
            },
            [
              _c(
                widget.widgetGallery.component.is,
                _vm._b(
                  { key: widget.widgetGallery.id, tag: "component" },
                  "component",
                  widget.widgetGallery,
                  false
                )
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }