var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 79.19 102",
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("g", { attrs: { id: "help-desk" } }, [
        _c("circle", {
          attrs: {
            cx: "39.59",
            cy: "33.73",
            r: "25.58",
            fill: "none",
            stroke: _vm.color,
            "stroke-miterlimit": "10",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M39.59,59.31h0c-22.29,0-37.64,8.91-38.59,38.68-.05,1.64,1.26,3.01,2.89,3.01h71.4c1.64,0,2.95-1.37,2.89-3.01-.96-29.77-16.3-38.68-38.59-38.68h0",
            fill: "none",
            stroke: _vm.color,
            "stroke-miterlimit": "10",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            x: "65.17",
            y: "21.92",
            width: "11.12",
            height: "23.63",
            rx: "4.2",
            ry: "4.2",
            fill: "none",
            stroke: _vm.color,
            "stroke-miterlimit": "10",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            x: "2.94",
            y: "21.92",
            width: "11.12",
            height: "23.63",
            rx: "4.2",
            ry: "4.2",
            fill: "none",
            stroke: _vm.color,
            "stroke-miterlimit": "10",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M9.06,21.92C13.8,9.68,25.68,1,39.59,1s25.8,8.68,30.54,20.92",
            fill: "none",
            stroke: _vm.color,
            "stroke-miterlimit": "10",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M51.19,49.74h12.45c2.9,0,5.39-1.74,6.49-4.23",
            fill: "none",
            stroke: _vm.color,
            "stroke-miterlimit": "10",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            x: "39.59",
            y: "45.93",
            width: "11.6",
            height: "7.63",
            rx: "3.81",
            ry: "3.81",
            fill: "none",
            stroke: _vm.color,
            "stroke-miterlimit": "10",
            "stroke-width": "2",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }