var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 188.34 101.03",
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("g", { attrs: { id: "rh" } }, [
        _c("circle", {
          attrs: {
            cx: "94.15",
            cy: "29.26",
            r: "28.26",
            transform: "translate(-4.03 38.26) rotate(-22.5)",
            fill: "none",
            stroke: _vm.color,
            "stroke-miterlimit": "10",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M136.79,100.26h0c-.03-.91-.08-1.79-.13-2.67-.01-.23-.03-.45-.04-.67-.06-.85-.12-1.68-.21-2.49-.01-.11-.03-.22-.04-.33-.07-.7-.16-1.38-.24-2.06-.04-.27-.07-.54-.11-.81-.09-.65-.19-1.28-.3-1.9-.06-.32-.12-.64-.18-.96-.08-.44-.17-.88-.26-1.32-.07-.34-.15-.69-.23-1.02-.08-.36-.17-.71-.26-1.06-.15-.6-.31-1.18-.48-1.76-.06-.22-.13-.44-.19-.65-.13-.41-.26-.82-.39-1.22-.01-.03-.02-.06-.03-.1-.3-.88-.62-1.73-.96-2.55-.03-.07-.05-.13-.08-.2-.55-1.3-1.14-2.53-1.78-3.68-7.13-12.84-20.19-17.29-36.71-17.29h0",
            fill: "none",
            stroke: _vm.color,
            "stroke-miterlimit": "10",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M94.15,57.52h0c-16.51,0-29.56,4.44-36.69,17.26-.65,1.16-1.24,2.39-1.79,3.69-.03.06-.05.13-.08.19-.34.81-.65,1.65-.95,2.51-.02.05-.03.09-.05.14-.14.4-.27.81-.39,1.22-.07.22-.13.43-.19.65-.17.58-.33,1.17-.48,1.77-.09.35-.18.7-.26,1.05-.08.34-.15.68-.23,1.02-.09.44-.18.88-.27,1.33-.06.32-.12.65-.18.98-.11.62-.21,1.24-.3,1.88-.04.27-.07.54-.11.81-.09.68-.17,1.36-.25,2.07-.01.11-.03.21-.04.32-.08.81-.15,1.65-.2,2.49-.02.22-.03.45-.04.68-.05.87-.1,1.76-.13,2.67h0",
            fill: "none",
            stroke: _vm.color,
            "stroke-miterlimit": "10",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            cx: "34.11",
            cy: "45.87",
            r: "21.94",
            transform: "translate(-14.96 16.54) rotate(-22.5)",
            fill: "none",
            stroke: _vm.color,
            "stroke-miterlimit": "10",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            cx: "154.24",
            cy: "45.87",
            r: "21.94",
            transform: "translate(-5.81 62.52) rotate(-22.5)",
            fill: "none",
            stroke: _vm.color,
            "stroke-miterlimit": "10",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M57.46,74.77s0,0,0,0c-5.78-5.06-13.82-6.96-23.35-6.96h0c-19.12,0-32.29,7.65-33.11,33.18",
            fill: "none",
            stroke: _vm.color,
            "stroke-miterlimit": "10",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M187.34,101c-.82-25.54-13.98-33.18-33.11-33.18h0c-9.54,0-17.6,1.9-23.38,6.98,0,0,0,0,0,0",
            fill: "none",
            stroke: _vm.color,
            "stroke-miterlimit": "10",
            "stroke-width": "2",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }