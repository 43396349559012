var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 85.32 102",
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("g", { attrs: { id: "doc" } }, [
        _c("path", {
          attrs: {
            d: "M15.23,89.9c-3.5.36-6.63-2.19-6.99-5.69L1.03,13.75c-.36-3.5,2.19-6.63,5.69-6.99L62.76,1.03c3.5-.36,6.63,2.19,6.99,5.69l1.95,19.05",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M63.36,17.44H21.62c-3.52,0-6.37,2.85-6.37,6.37v70.82c0,3.52,2.85,6.37,6.37,6.37h56.33c3.52,0,6.37-2.85,6.37-6.37v-56.23l-20.97-20.97Z",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M84.32,38.4h-13.5c-4.12,0-7.46-3.34-7.46-7.46v-13.5",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("line", {
          attrs: {
            x1: "28.8",
            y1: "51.6",
            x2: "71.34",
            y2: "51.6",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("line", {
          attrs: {
            x1: "28.8",
            y1: "59.31",
            x2: "71.34",
            y2: "59.31",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("line", {
          attrs: {
            x1: "28.8",
            y1: "67.03",
            x2: "71.34",
            y2: "67.03",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("line", {
          attrs: {
            x1: "28.8",
            y1: "74.74",
            x2: "71.34",
            y2: "74.74",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("line", {
          attrs: {
            x1: "28.8",
            y1: "82.46",
            x2: "47.06",
            y2: "82.46",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }