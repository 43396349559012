var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 91.73 102",
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("g", { attrs: { id: "stock" } }, [
        _c("polygon", {
          attrs: {
            points: "1.11 28.25 45.78 1 90.45 28.25 45.78 55.5 1.11 28.25",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("polyline", {
          attrs: {
            points: "90.45 28.25 90.62 73.75 45.95 101 1.29 73.75 1.11 28.25",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("line", {
          attrs: {
            x1: "45.78",
            y1: "55.5",
            x2: "45.78",
            y2: "100.45",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("line", {
          attrs: {
            x1: "68.2",
            y1: "14.63",
            x2: "23.53",
            y2: "41.88",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }