import { Component, Vue } from 'vue-property-decorator';

import WidgetSidebar from '@/core/spe/kiosk/widget-sidebar/widget-sidebar.vue';
import GoSvgCheckList from '@/core/spe/kiosk/components/go-svg-check-list.vue';
import GoSvgDocs from '@/core/spe/kiosk/components/go-svg-docs.vue';
import GoSvgHelpDesk from '@/core/spe/kiosk/components/go-svg-help-desk.vue';
import GoSvgRh from '@/core/spe/kiosk/components/go-svg-rh.vue';
import GoSvgStock from '@/core/spe/kiosk/components/go-svg-stock.vue';

@Component({
  components: {
    WidgetSidebar,
    GoSvgCheckList,
    GoSvgDocs,
    GoSvgHelpDesk,
    GoSvgRh,
    GoSvgStock,
  },
})
export default class kiosk extends Vue {
  /***
   * {
   *    title: this.$t('title'),
   *    subtitle: this.$t('subtitle'),
   *    url: 'http://localhost',
   *    text: {
   *      component: 'VIcon',
   *      properties: {
   *        color: 'white',
   *        class: 'mdi mdi-format-list-bulleted-triangle',
   *        size: '70',
   *      },
   *    },
   *  },
   */
  public items = [
    {
      title: this.$t('speKiosk.documentation.title'),
      subtitle: this.$t('speKiosk.documentation.subtitle'),
      url: 'http://martin-001.internal.grupo-valco.com:50102/spe/go-file-browser',
      text: {
        component: 'go-svg-docs',
        properties: {
          color: 'white',
          width: '58',
          height: '70',
        },
      },
    },
    {
      title: this.$t('speKiosk.humanResources.title'),
      subtitle: this.$t('speKiosk.humanResources.subtitle'),
      url: 'https://marut.roots.grupo-valco.com',
      color: 'white',
      text: {
        component: 'go-svg-rh',
        properties: {
          color: 'white',
          width: '130',
          height: '70',
        },
      },
    },
    {
      title: this.$t('speKiosk.helpDesk.title'),
      subtitle: this.$t('speKiosk.helpDesk.subtitle'),
      url: 'https://sentry.roots.grupo-valco.com/otrs/customer.pl',
      text: {
        component: 'go-svg-help-desk',
        properties: {
          color: 'white',
          width: '54',
          height: '70',
        },
      },
    },
    {
      title: this.$t('speKiosk.stockTransactions.title'),
      subtitle: this.$t('speKiosk.stockTransactions.subtitle'),
      url: 'https://wibault.roots.grupo-valco.com/stock/miscellaneous-issues',
      text: {
        component: 'go-svg-stock',
        properties: {
          color: 'white',
          width: '63',
          height: '70',
        },
      },
    },
    {
      title: this.$t('speKiosk.stock.title'),
      subtitle: this.$t('speKiosk.stock.subtitle'),
      url: 'https://aurora.roots.grupo-valco.com/stockcontrol/material-request/create/shop-floor',
      text: {
        component: 'go-svg-check-list',
        properties: {
          color: 'white',
          width: '58',
          height: '70',
        },
      },
    },
  ];

  public onClick(url: string) {
    window.open(url, '_blank');
  }
}
