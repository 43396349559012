import { Module } from 'vuex';
import { StatusType } from '@/shared/model/raptor/status-type.model';
import ApplicationContext from '@/shared/raptor/context/application-context';
import { bottomSheetStoreMutationTypes, yakovlevStoreMutationTypes } from '@/shared/config/store/raptor/mutation-types/types';
import dispatcherMessage from '@/shared/raptor/messaging/handler/dispatcher-message';
import templateParser from '@/shared/raptor/messaging/support/template-parser';
import * as AlertUtils from '@/shared/raptor/util/alert-utils';
import { IShopFloorDevice } from '@/shared/model/shop-floor-device.model';
import { ComponentType } from '@/shared/model/enumerations/component-type.model';
import ShopFloorDeviceExtendedService from '@/entities/shop-floor-device/shop-floor-device-extended.service';
import TextFileService from '@/entities/text-file/text-file.service';
import TextFileExtendedService from '@/entities/text-file/text-file-extended.service';
import { appLogLevel, LogLevel } from '@/shared/config/logger-spe';
import { DeviceTextFileProperties, IPartialUpdateTextInnerProperties } from '@/shared/model/raptor/work-center-device.model';

const shopFloorDeviceExtendedService = new ShopFloorDeviceExtendedService();
const textFileService = new TextFileService();
const textFileExtendedService = new TextFileExtendedService();
const logLevel = appLogLevel();

export interface workCenterDeviceStateStorable {
  stages: { [k: string]: any };
  steps: { [k: string]: any };
  devices: Array<IShopFloorDevice>;
}

/**
 * A stage block defines a conceptually distinct subset of tasks performed through the entire Pipeline.
 *
 *
 * "stages" are called when start, pause, skip, complete, etc. happen. All devices are scanned for the specified stage,
 * and stage block get executed by the dispatcher.
 *
 * The "message-received" stage is called when a message is received from the mqtt broker. The device is found, the
 * stage block is found by matching the payload header endpoint with the stage block name, finally the stage block get
 * executed by the dispatcher.
 *
 *
 *
 *
 *
 * Actions
 *      mqtt_topic              -> triggers a publish message into device.topic
 *                                 Requires topic and message arguments
 *      mqtt_receivedTopic      -> the segments
 */
export const defaultWorkCenterDeviceState: workCenterDeviceStateStorable = {
  stages: {
    start: 'start',
    messageReceived: 'message-received',
  },
  steps: {
    sendMqttMessage: 'send-mqtt-message',
    /**
     * @deprecated Using the linkDocumentToWorkOrderThenTrack() is preferable, backend is called once.
     * - Stages
     *    [
     *     {
     *        "name":"start",
     *        "description":"Called when the user as pressed start on the raptor operation landing interface.",
     *        "steps":[
     *           {
     *              "name":"send-mqtt-message",
     *  			      "filter": "this.device.type === 'MQTT'",
     *              "request":{
     *                 "parameters":null,
     *                 "message":"{\"headers\":{\"type\":\"request\",\"endpoint\":\"job\",\"uuid\":\"${this.uuidv4()}\"},\"payload\":{\"ZMFGPLN_0\":\"${this.payload.message.payload.manufacturingPlan}\",\"WST_0\":\"${this.payload.message.payload.workCenter}\",\"EXTQTY_0\":${this.remainingAmount(this.payload.message.payload.data)},\"MFGNUM_0\":\"${this.payload.message.payload.data['MFGITM__MFGNUM_0']}\",\"OPENUM_0\":${this.payload.message.payload.data['ZOPEWRKF__OPENUM_0']},\"OPESPLNUM_0\":${this.payload.message.payload.data['ZOPEWRKF__OPESPLNUM_0']},\"ZDES_0\":\"${this.product(this.payload.message.payload.data)}\"}}"
     *              },
     *              "response":null
     *           }
     *        ]
     *     },
     *     {
     *        "name":"message-received",
     *        "description":"",
     *        "steps":[
     *           {
     *              "name":"link-document-to-work-order",
     *  	          "filter": "true",
     *              "request":{
     *                 "parameters":"{\"orderNo\":\"${this.payload.MFGNUM_0}\",\"operation\":${this.payload.OPENUM_0},\"operationSplit\":${this.payload.OPESPLNUM_0}}",
     *                 "message":null
     *              },
     *              "response":{
     *                 "message":"{\"headers\":{\"type\":\"response\",\"endpoint\":\"link-document-to-work-order\",\"uuid\":\"${this.payload.message.headers.uuid}\",\"status\":${this.payload.response.status},\"message\":\"${this.payload.response.statusText}\"},\"payload\":${this.payload.response.hasOwnProperty('object') ? JSON.stringify( { ztagnum0: this.payload.response.object.ztagnum0, rowid: this.payload.response.object.rowid ?? 0 } ) : '{}' }}"
     *              }
     *           }
     *        ]
     *     }
     */
    linkDocumentToWorkOrder: 'link-document-to-work-order',
    linkDocumentToWorkOrderThenTrack: 'link-document-to-work-order-then-track',
    completeWithoutOperationTrack: 'complete-without-operation-track',
    operationTrack: 'operation-track',
    operationTrackWithoutContext: 'operation-track-without-context',
    printLabel: 'print-label',
    doorInspect: 'door-inspect',
    conveyorArrivalPart: 'conveyor-arrival-part',
  },
  devices: [],
};

/**
 * Asynchronous process
 */
const actions = {
  /**
   * Fetch device list from api
   * @param commit
   * @param payload
   */
  fetchData(context, payload) {
    return new Promise((resolve, reject) => {
      const criteria = {
        'workCenter.equals': payload.workCenter,
        'site.equals': payload.manufacturingSite,
      };
      shopFloorDeviceExtendedService
        .retrieve(criteria, null)
        .then(response => {
          // Try to deserializable text in TextFile
          response.data.forEach((device: IShopFloorDevice) => {
            const keys = ['stages', 'statistics', 'fields'];
            keys.forEach(key => {
              if (device[key]?.text) {
                try {
                  device[key].text = JSON.parse(device[key].text);
                } catch (e) {
                  console.error(`Failed to deserialize '${key}': ${e.message}`, e);
                  throw new Error(`Failed to deserialize '${key}': ${e.message}`);
                }
              }
            });
          });
          context.commit('devices', response.data);
          const devices = context.state.devices.map(it => {
            return {
              id: it.id,
              code: it.code,
              icon: it.icon || 'mdi-link-box',
              type: StatusType.Device,
              color: 'success',
              title: it.description,
              connected: true,
              countDownToReconnect: false,
              reconnect: false,
              reconnectInterval: 0,
              message: ApplicationContext.getI18n().t('raptor.operatorLanding.connected'),
              error: null,
            };
          });
          context.dispatch(bottomSheetStoreMutationTypes.reset, devices, { root: true }).then(response => {
            if (Array.isArray(response)) {
              response.forEach(it => console.debug(`Device ${it.code} added to bottom sheet.`));
            } else {
              console.debug(`Device ${response.code} added to bottom sheet.`);
            }
          });
          resolve(response);
        })
        .catch(error => {
          AlertUtils.showError(error);
          reject(error);
        });
    });
  },

  /**
   * Subscribe mqtt channels
   * @param commit
   * @param payload
   */
  subscribe({ dispatch, state }, payload) {
    // Main topic subscription
    dispatch(
      yakovlevStoreMutationTypes.subscribeTopic,
      templateParser.fill(`raptor/${payload.manufacturingPlan}/${payload.workCenter}`, { payload }),
      { root: true }
    );
    // Devices subscription to output channel
    const subscribableComponentTypes = Object.values(ComponentType);
    state.devices
      .filter((device: IShopFloorDevice) => subscribableComponentTypes.includes(device.type))
      .forEach((device: IShopFloorDevice) => {
        dispatch(yakovlevStoreMutationTypes.subscribeTopic, templateParser.fill(device.output, { device, payload }), { root: true });
      });
  },

  /**
   * Received from WS
   * @param commit
   * @param data messageEvent
   */
  messageReceived({ commit }, data) {
    dispatcherMessage.messageReceived(data);
  },

  start({ commit }, data) {
    dispatcherMessage.start(data);
  },

  complete({ commit }, data) {
    dispatcherMessage.complete(data);
  },

  /**
   * Sets active state
   * @param commit
   * @param payload
   */
  update(context, payload) {
    logLevel === LogLevel.DEBUG && console.debug('update : context=', context, ', payload=', payload);
    return new Promise((resolve, reject) => {
      // Serialize TextFileFile before update
      Object.values(DeviceTextFileProperties).forEach(property => {
        if (typeof payload[property]?.text === 'object' && payload[property]?.text !== null) {
          payload[property].text = JSON.stringify(payload[property].text);
        }
      });

      shopFloorDeviceExtendedService
        .update(payload)
        .then(res => {
          // Deserialize TextFileFile before update
          Object.values(DeviceTextFileProperties).forEach(property => {
            if (typeof res[property]?.text === 'string' && res[property]?.text !== null) {
              res[property].text = JSON.parse(res[property].text);
            }
          });
          context.commit('updateDevice', res);
          resolve(res);
        })
        .catch(err => {
          AlertUtils.showError(err);
          reject(err);
        });
    });
  },

  updateDeviceTextFile(context, payload) {
    logLevel === LogLevel.DEBUG && console.debug('updateDeviceTextFile : context=', context, ', payload=', payload);
    if (!Object.values(DeviceTextFileProperties).includes(payload.property as DeviceTextFileProperties)) {
      throw new Error(`Invalid property: ${payload.property}. It must be one of ${Object.values(DeviceTextFileProperties).join(', ')}.`);
    }

    // @ts-ignore
    if (payload.textFile.text instanceof Object) {
      payload.textFile.text = JSON.stringify(payload.textFile.text);
    }
    return new Promise((resolve, reject) => {
      textFileService
        .update(payload.textFile)
        .then(res => {
          res.text = JSON.parse(res.text);
          context.commit('updateDeviceTextFile', { device: payload.device, property: payload.property, textFile: res });
          resolve(res);
        })
        .catch(err => {
          AlertUtils.showError(err);
          reject(err);
        });
    });
  },

  partialUpdateTextInnerProperties(context, payload: IPartialUpdateTextInnerProperties) {
    logLevel === LogLevel.DEBUG && console.debug('partialUpdateTextInnerProperties : context=', context, ', payload=', payload);
    return new Promise((resolve, reject) => {
      textFileExtendedService
        .partialUpdateTextInnerProperties(payload.id, payload.innerProperties)
        .then(res => {
          res.text = JSON.parse(res.text);
          // context.commit('updateDeviceTextFile', { device: payload.device, property: payload.property, textFile: res });
          resolve(res);
        })
        .catch(err => {
          AlertUtils.showError(err);
          reject(err);
        });
    });
  },
};

export const workCenterDeviceStore: Module<workCenterDeviceStateStorable, any> = {
  state: { ...defaultWorkCenterDeviceState },
  getters: {
    stages: state => state.stages,
    steps: state => state.steps,
    devices: state => state.devices,
  },
  mutations: {
    devices(state, payload) {
      state.devices = payload;
    },
    updateDevice(state, payload) {
      state.devices.forEach((it, i) => {
        if (it.id === payload.id) {
          state.devices.splice(i, 1, payload);
        }
      });
    },
    updateDeviceTextFile(state, payload) {
      const index = state.devices.findIndex(it => it.id === payload.device.id);
      index !== -1 && (state.devices[index][payload.property] = payload.textFile);
    },
  },
  actions,
  namespaced: true,
};
