var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 84.66 102",
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("g", { attrs: { id: "epi" } }, [
        _c("line", {
          attrs: {
            x1: "37.1",
            y1: "26.64",
            x2: "69.7",
            y2: "26.64",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("line", {
          attrs: {
            x1: "37.1",
            y1: "51.13",
            x2: "69.7",
            y2: "51.13",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("line", {
          attrs: {
            x1: "37.1",
            y1: "75.61",
            x2: "69.7",
            y2: "75.61",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("polyline", {
          attrs: {
            points: "13.58 27.26 17.9 31.57 28.27 21.2",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("polyline", {
          attrs: {
            points: "13.58 52.01 17.9 56.32 28.27 45.94",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("polyline", {
          attrs: {
            points: "13.58 76.49 17.9 80.8 28.27 70.43",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            x: "1",
            y: "1",
            width: "82.66",
            height: "100",
            rx: "7.63",
            ry: "7.63",
            fill: "none",
            stroke: _vm.color,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }