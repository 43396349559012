import { Component, Provide, Vue } from 'vue-property-decorator';

import GoGadgetCalendar from '@/core/spe/kiosk/widget-sidebar/go-components/go-gadget-calendar/go-gadget-calendar.vue';
import GoGadgetExpandable from '@/core/spe/kiosk/widget-sidebar/go-components/go-gadget-expandable/go-gadget-expandable.vue';
import GoGadgetRedirect from '@/core/spe/kiosk/widget-sidebar/go-components/go-gadget-redirect/go-gadget-redirect.vue';
import GoGadgetCalendarEventList from '@/core/spe/kiosk/widget-sidebar/go-components/go-gadget-calendar-event-list/go-gadget-calendar-event-list.vue';

import { IWidgetNotificationCenter } from '@/shared/model/spe/kiosk/widget-sidebar/widget-notification-center/widget-notification-center.model';
import WidgetNotificationCenterExtendedService from '@/entities/spe/kiosk/widget-sidebar/widget-notification-center/widget-notification-center-extended.service';

@Component({
  components: {
    GoGadgetCalendar,
    GoGadgetExpandable,
    GoGadgetCalendarEventList,
    GoGadgetRedirect,
  },
})
export default class WidgetSidebar extends Vue {
  @Provide('widgetNotificationCenterExtendedService')
  private widgetNotificationCenterExtendedService = () => new WidgetNotificationCenterExtendedService();

  public widgetNotificationCenters: IWidgetNotificationCenter[] = [];

  public error: { [k: string]: any } = null;

  public isFetching = false;
  public currentLanguage = '';

  created(): void {
    this.currentLanguage = this.$store.getters.currentLanguage;
    this.$store.watch(
      () => this.$store.getters.currentLanguage,
      () => {
        this.currentLanguage = this.$store.getters.currentLanguage;
        this.$vuetify.lang.current = this.currentLanguage.substring(0, 2);
      }
    );
  }

  public mounted(): void {
    this.retrieveAllWidgetsAvailable();
    setInterval(() => {
      this.retrieveAllWidgetsAvailable();
    }, 300000); //set interval for 60 seconds
  }

  public retrieveAllWidgetsAvailable(): void {
    this.isFetching = true;
    // Clean-up
    this.widgetNotificationCenters = [];
    this.error = null;

    const criteria = { 'published.equals': 'TRUE' };

    this.widgetNotificationCenterExtendedService()
      .retrieveOrder(criteria)
      .then(res => {
        this.widgetNotificationCenters = res.data.map((it: IWidgetNotificationCenter) => {
          try {
            it.widgetGallery.component = JSON.parse(it.widgetGallery.component);
          } catch (ignore) {}
          try {
            it.widgetGallery.data = JSON.parse(it.widgetGallery.data);
          } catch (ignore) {}
          return it;
        });
        this.isFetching = false;
      })
      .catch(err => {
        console.error(`An error occurred while retrieving data : ${err.message} `, err);
        this.error = err;
      });
  }

  public errorResponseTitle(): string {
    return this.error?.response?.data?.title;
  }
}
