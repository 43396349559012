/**
 * IShopFloorDevice text file relations
 */
export enum DeviceTextFileProperties {
  STAGES = 'stages',
  STATISTICS = 'statistics',
  FIELDS = 'fields',
}

export enum DeviceTextFileFieldsProperties {
  DATA = 'data',
  DATA_EXIT_TYPES = 'exitTypes',
  DATA_INSPECT_NOW = 'inspectNow',
  DATA_INSPECTION_MODE_ID = 'inspectionModeId',
  DATA_EXIT_TYPE_ID = 'exitTypeId',
}

export interface IPartialUpdateTextInnerProperties {
  id?: number;
  innerProperties?: { [k: string]: any };
}

export class PartialUpdateTextInnerProperties implements IPartialUpdateTextInnerProperties {
  constructor(public id?: number, public innerProperties?: { [k: string]: any }) {}
}
