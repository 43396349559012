import axios from 'axios';

import { ITextFile } from '@/shared/model/text-file.model';

const baseApiUrl = '/services/fargo/api/v1/text-files';

export default class TextFileExtendedService {
  public partialUpdateTextInnerProperties(id: number, innerProperties: { [k: string]: any }): Promise<ITextFile> {
    return new Promise<ITextFile>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${id}/text/inner-properties`, innerProperties)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
