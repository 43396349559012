var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              style: {
                minWidth: `calc(100% - ${
                  _vm.$vuetify.breakpoint.lgAndUp ? 323 : 0
                }px)`,
              },
            },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("h2", [
                          _vm._v(_vm._s(_vm.$t("speKiosk.home.title"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    _vm._l(_vm.items, function (item, i) {
                      return _c(
                        "v-col",
                        {
                          key: i,
                          attrs: {
                            cols: "10",
                            sm: "4",
                            md: "6",
                            lg: "3",
                            xlg: "2",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "white--text",
                              attrs: { color: "black" },
                              on: {
                                click: function ($event) {
                                  return _vm.onClick(item.url)
                                },
                              },
                            },
                            [
                              _c("v-card-title", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t(item.title)) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card-subtitle",
                                { staticClass: "white--text" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t(item.subtitle)) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    item.text.component,
                                    _vm._b(
                                      { tag: "component" },
                                      "component",
                                      item.text.properties,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.lgAndUp
            ? _c(
                "v-col",
                { style: { width: "323px" } },
                [_c("widget-sidebar")],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }